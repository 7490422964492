RootView = require 'views/core/RootView'
User = require 'models/User'
template = require 'templates/new-view'

module.exports = class NewView extends RootView
  id: 'new-view'
  template: template
  events:
    'click #some-btn': 'onClickSomeButton'

  logoutRedirectURL: false

  onClickSomeButton: (e) ->
    console.log 'Some button was clicked', e
    alert "Haris alerted this.. Thank God. "
    user = new User({_id: "5979c94a396e8c0244021f4e"})
    user.set('email': "newtest@baabte.com")
    user.save()
    console.log (user)


  initialize: (options) ->
    name2 = "haris"
    console.log(name2)
    console.log("The abovekjhk one is user ")
