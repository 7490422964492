RootView = require 'views/core/RootView'
forms = require 'core/forms'
template = require 'templates/form-test'


module.exports = class FormTest extends RootView
  id: 'formTest'
  template: template
  events:
    'click #some-btn': 'onClickSomeButton'

  logoutRedirectURL: false

  
  # console.log(testvariable)
  # testobj = new TodoTxtBuilder
  root = exports ? this
  root.foo = -> 'Hello World haris here'
  @test = "haristestwindow"
  console.log "The value of foo is " + root.foo()
  # root.form = @$('form')
  console.log "The root.form is " + root.form 

  class TodoTxtBuilder
    constructor: (defaultParameters = {}) ->
      @date     = defaultParameters.date     or new Date
      @title    = defaultParameters.title    or 'haris'
      @projects = defaultParameters.projects or ['project1','project2', 'project3', 'project4'  ]
      @priority = defaultParameters.priority or 5

  onClickSomeButton: (e) ->
    # console.log 'Some button was clicked', e
    # alert "Haris alerted this.. Thank God. "
    # user = new User({_id: "5979c94a396e8c0244021f4e"})
    # user.set('email': "newtest@baabte.com")

    # user.save()
    console.log("The object is ******")
    console.log "The value of foo is " + root.foo()
    formdata = forms.formToObject(@$('#haris-form'))
    console.log (formdata)
    testvar = @$('#name-input').val()
    console.log("The variable name is ")
    console.log(testvar)


  initialize: (options) ->
    name2 = "haris"
    console.log(name2)
    console.log("The above one is user ")

  getName: ->
    obj = {'title':  "titleparamer", 'projects' : ['paramproject1','paramproject2','paramproject3','paramproject4']}
    testobj =  new TodoTxtBuilder(obj)
    return @test